import queryString from 'query-string';
import { all, put, takeLatest, select } from 'redux-saga/effects';

import { playlistsSelector } from './playlist.selectors';
import PlaylistActionTypes from './playlist.types';
import { playlistQuerySuccess, fetchSelectedPlaylistSuccess, playlistLoadNextSuccess } from './playlist.actions';

import * as calls from '../api/calls/playlist.calls';
import { searchSelector } from '../search/search.selectors';
import { getOrgSettings, setBranding } from '../organization/organization.slice';
import { orgSelector } from '../organization/organization.selectors';
import handleUnaothrizedContentResponse from '../../utils/handleUnaothrizedContentResponse';
import { applyLocaleIfNeeded, setPreferredLanguage } from '../../locale/i18n';
import { setShowSomethingWentWrong } from '../appActivity/appActivity.slice';
import { log, logError } from '../tracking/tracking.slice';
import { cloneDeep } from 'lodash';
import { EShowSomethingWentWrongError } from '../appActivity/appActivity.types';
import { EContentType } from '../geofence/geofence.types';
import { setGeofenceContentInfo } from '../geofence/geofence.slice';
import history from '../../navigation/history';
import { applyOnboardingToken } from '../onboarding/onboarding.saga';
import { getOrganizationFeatureFlags } from '../profile/profile.actions';
import { NEW_FEED_ORG_IDS } from '../../utils/constants';

function* query(action) {
  const { orgId, isPublicFeed } = action.payload;

  const search = yield select(searchSelector);
  const { id: activeOrgId } = yield select(orgSelector);

  const newFeedFeatureFlag = NEW_FEED_ORG_IDS.includes(orgId || activeOrgId);

  const params: any = {
    feed: true,
    page_size: 20,
    pagination: true,
    organization: orgId || activeOrgId,
  };

  if (search.playlist) {
    params.search = search.playlist;
  }

  const queryParams = '?' + queryString.stringify(params);

  try {
    yield applyOnboardingToken();
    const {
      data: { results, count },
    } = isPublicFeed
      ? yield calls.queryPublicFeed(queryParams)
      : newFeedFeatureFlag
      ? yield calls.queryFeed(params.organization, queryParams)
      : yield calls.query(queryParams);
    yield put(
      playlistQuerySuccess({
        playlists: results,
        totalPlaylists: count,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'playlist.saga query: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
  }
}

function* loadNext(action) {
  const { orgId, isPublicFeed } = action.payload;

  const search = yield select(searchSelector);
  const playlistsState = yield select(playlistsSelector);
  const { id: activeOrgId } = yield select(orgSelector);

  const newFeedFeatureFlag = NEW_FEED_ORG_IDS.includes(orgId || activeOrgId);

  const params = {
    feed: true,
    page_size: 20,
    pagination: true,
    page: playlistsState.nextPage,
    organization: orgId || activeOrgId,
  };

  if (search.playlist) {
    // @ts-ignore
    params.search = search.playlist;
  }

  const queryParams = '?' + queryString.stringify(params);

  try {
    const { data } = isPublicFeed
      ? yield calls.queryPublicFeed(queryParams)
      : newFeedFeatureFlag
      ? yield calls.queryFeed(params.organization, queryParams)
      : yield calls.query(queryParams);
    yield put(playlistLoadNextSuccess(data.results));
  } catch (error) {
    yield put(
      logError({
        event: 'playlist.saga loadNext: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
  }
}

function* getById(action) {
  const { playlistId, processId, isFromBite } = action.payload;
  yield put(
    log({
      event: 'playlist.saga getById: start',
      processId,
      data: { playlistId },
    }),
  );
  try {
    yield applyOnboardingToken();
    const { data: playlist } = yield calls.getById(playlistId, !isFromBite ? { 'x-geofence': true } : {});
    yield put(
      log({
        event: 'playlist.saga getById: result',
        processId,
        data: { playlist },
      }),
    );

    yield put(getOrganizationFeatureFlags(playlist.organization));

    yield setPlaylistResult({ playlist });
  } catch (error) {
    yield put(
      logError({
        event: 'playlist.saga getById: error',
        processId,
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );

    if (error?.response?.data?.detail?.orgid) {
      yield setPreferredLanguage({ orgId: error.response.data.detail.orgid });
    }

    // On getting 428 status code, we should set geofence component active and set geofence content info else handle unauthorized content response
    if (error?.response?.status === 428) {
      yield put(setGeofenceContentInfo({ type: EContentType.playlist, id: playlistId, processId }));
      history.replace('/playlists');
      return;
    }

    yield handleUnaothrizedContentResponse({ error, processId });
  }
}

export function* setPlaylistResult({ playlist }) {
  yield put(getOrgSettings(playlist.organization));
  yield setPreferredLanguage({ orgId: playlist.organization });
  yield put(fetchSelectedPlaylistSuccess(playlist));
  yield put(setBranding(playlist.branding));
  yield applyLocaleIfNeeded(playlist.branding.locale);
}

export default function* playlistSaga() {
  yield all([
    takeLatest(PlaylistActionTypes.PLAYLIST_QUERY_REQUEST, query),
    takeLatest(PlaylistActionTypes.PLAYLIST_LOAD_NEXT, loadNext),
    takeLatest(PlaylistActionTypes.FETCH_SELECTED_PLAYLIST_REQUEST, getById),
  ]);
}
