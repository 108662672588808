import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../navigation/MainApp/components/Header';
import { ReactComponent as HomeButton } from '../../assets/icons/home.svg';
import { useHistory } from 'react-router-dom';
import PromptInput from './common/PromptInput';
import KnowledgeList from '../../components/knowledge/KnowledgeList';
import useHomeScreenLogic from '../Home/useHomeScreenLogic';
import Answer from './common/Answer';
import Suggestions from './common/Suggestions';
import { isMobile } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  knowledgeIsLoadingSelector,
  knowledgeLocalPromptSelector,
  knowledgeRelatedContentSelector,
  knowledgeTagsSearchDataSelector,
} from '../../store/knowledge/knowledge.selectors';
import { fetchKnowledge, resetKnowledge } from '../../store/knowledge/knowledge.slice';
import { log } from '../../store/tracking/tracking.slice';
import { orgSelector } from '../../store/organization/organization.selectors';
import { homeButtonCy } from './aiPrompt.constants';
import { organizationHasFeatureFlagSelector } from '../../store/profile/profile.selectors';
import { Features } from '../../utils/featureFlag/featureFlag.types';

const Prompt: React.FC = () => {
  const dispatch = useDispatch();
  const isDevice = isMobile();
  const history = useHistory();
  const { handleBiteClick, handlePlaylistClick } = useHomeScreenLogic();
  const isLoading = useSelector(knowledgeIsLoadingSelector);
  const localPrompt = useSelector(knowledgeLocalPromptSelector);
  const { searchRelated, answerRelated } = useSelector(knowledgeRelatedContentSelector);
  const searchData = useSelector(knowledgeTagsSearchDataSelector);
  const { id: orgId } = useSelector(orgSelector);
  const askAiFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.ASK_AI_FEED,
    }),
  );

  const [isEditMode, setIsEditMode] = useState(false);

  const showSuggestions = !isLoading && !searchRelated && !answerRelated && !searchData;

  const handleHomeButtonClick = useCallback(() => {
    dispatch(log({ event: 'Prompt.handleHomeButtonClick' }));
    history.push('/');
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(log({ event: 'Prompt.opened', data: { orgId } }));

    if (!askAiFeatureFlag) {
      dispatch(log({ event: 'Prompt.redirect: access denied', data: { orgId } }));
      history.replace('/');
    }

    return () => {
      dispatch(resetKnowledge({ excludeFields: ['history'] }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const handleApplyClick = useCallback(() => {
    dispatch(log({ event: 'Prompt.handleApplyClick', data: { localPrompt } }));
    dispatch(fetchKnowledge(localPrompt));
    setIsEditMode(false);
  }, [dispatch, localPrompt]);

  const renderRight = useCallback(() => {
    return (
      <S.HomeButtonContainer data-cy={homeButtonCy} onClick={handleHomeButtonClick}>
        <S.HomeButton />
      </S.HomeButtonContainer>
    );
  }, [handleHomeButtonClick]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    setIsEditMode(false);
  }, [isLoading]);

  if (!askAiFeatureFlag) {
    return null;
  }

  return (
    <S.Container isDevice={isDevice}>
      <Header renderRight={renderRight} />
      <S.Content>
        <PromptInput isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        <Answer />
        <KnowledgeList onBiteClick={handleBiteClick} onPlaylistClick={handlePlaylistClick} />
      </S.Content>
      {showSuggestions && <Suggestions onApplyClick={handleApplyClick} />}
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ isDevice: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    padding: 0 16px;
    overflow: hidden;
  `,
  HomeButtonContainer: styled.div`
    width: 41px;
    height: 41px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
    margin: 0 16px;
  `,
  HomeButton: styled(HomeButton)`
    width: 24px;
    height: 24px;
  `,
  Top: styled.div``,
  Bottom: styled.div`
    margin-top: 32px;
  `,
};

export default Prompt;
