import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getNextPlaylistItem } from './utils';

import { ContinueButton } from '../../../components/shared';
import { selectedPlaylistSelector } from '../../../store/playlist/playlist.selectors';
import { selectedBiteSelector } from '../../../store/bite/bite.selectors';
import { authSelector } from '../../../store/auth/auth.selectors';

const GoToNextBiteBlueButton: FC = () => {
  const history = useHistory();
  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);
  const { selectedBite } = useSelector(selectedBiteSelector);
  const auth = useSelector(authSelector);

  return <S.ContinueButton onClick={() => getNextPlaylistItem(history, selectedPlaylist, selectedBite, auth)} />;
};

const S = {
  ContinueButton: styled(ContinueButton)`
    margin-top: 20px;
  `,
};

export default GoToNextBiteBlueButton;
