import { useCallback, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useCustomHistory from './useCustomHistory';

import { enableVideoPlaying } from '../store/bite/bite.actions';
import { biteSectionTypesSelector, selectedBiteSelector } from '../store/bite/bite.selectors';
import { selectedPlaylistSelector } from '../store/playlist/playlist.selectors';
import { getNextPlaylistItem } from '../screens/viewBite/GoToNextBite/utils';
import { authSelector } from '../store/auth/auth.selectors';
import { PUBLIC_FEED_ROUTES } from '../navigation/Routes';

interface IParams {
  sectionType: string; //limit to the 4 types of sections
}

export default ({ sectionType }: IParams) => {
  const dispatch = useDispatch();

  const biteSectionTypes = useSelector(biteSectionTypesSelector);
  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);
  const { selectedBite } = useSelector(selectedBiteSelector);
  const auth = useSelector(authSelector);
  const setTimeoutRef = useRef(null);

  const {
    selectedBite: { id: biteShareId },
  } = useSelector(selectedBiteSelector);

  const { history, historyPushWithPersistedQueryParams } = useCustomHistory();

  const nextSectionPath = useMemo(() => {
    const currentPathIdx = biteSectionTypes.findIndex((currSectionType) => currSectionType === sectionType);
    return biteSectionTypes[currentPathIdx + 1];
  }, [biteSectionTypes, sectionType]);

  const handleMoveNext = useCallback(() => {
    if (nextSectionPath) {
      historyPushWithPersistedQueryParams(`/bites/${biteShareId}/${nextSectionPath}`);
      return;
    }

    if (selectedPlaylist) {
      getNextPlaylistItem(history, selectedPlaylist, selectedBite, auth);
      return;
    }

    const publicFeedRoute = PUBLIC_FEED_ROUTES.find((route) => route.orgId === parseInt(selectedBite?.orgid, 10));
    if (publicFeedRoute) {
      historyPushWithPersistedQueryParams(publicFeedRoute.path);
      return;
    }

    history.push('/');
  }, [
    auth,
    biteShareId,
    history,
    historyPushWithPersistedQueryParams,
    nextSectionPath,
    selectedBite,
    selectedPlaylist,
  ]);

  const proceedToNextSection = useCallback(async () => {
    clearTimeout(setTimeoutRef.current);

    dispatch(enableVideoPlaying());
    if (!window.location.pathname.includes(nextSectionPath)) {
      handleMoveNext();
    }
  }, [dispatch, handleMoveNext, nextSectionPath]);

  const proceedToNextSectionWithDelay = useCallback(
    (isShortDelay?: boolean) =>
      (setTimeoutRef.current = setTimeout(
        () => {
          proceedToNextSection();
        },
        isShortDelay ? 375 : 2500,
      )),
    [proceedToNextSection],
  );

  return {
    proceedToNextSectionWithDelay,
    proceedToNextSection,
    nextSectionPath,
  };
};
