import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import type { RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ControllersBar from './components/ControllersBar';
import useHomeScreenLogic from './useHomeScreenLogic';

import ErrorModal from '../../components/shared/CustomModal/ErrorModal';
import { ReactComponent as FeedLoader } from '../../assets/loaders/feed-loader.svg';
import InfinitePlaylistList from '../../components/playlist';
import { Loader } from '../../components/shared';
import { playlistsSelector } from '../../store/playlist/playlist.selectors';
import { bitesSelector } from '../../store/bite/bite.selectors';
import InfiniteBiteList from '../../components/bite/InfiniteBiteList';
import useGetOrganizationLogo from '../../hooks/useGetOrganizationLogo';
import { HEADER_HEIGHT } from '../../components/layout/StyledHeader';
import OnlyTeamContentModal from '../../components/shared/CustomModal/OnlyTeamContentModal';
import NoContentModal from '../../components/shared/CustomModal/NoContentModal';
import gtmTrack from '../../services/googleTagManager/track';
import useRemoveQueryParams from '../../hooks/useRemoveQueryParams';
import AskMeAnythingBottomSheet from './components/AskMeAnythingBottomSheet';
import { CONTROLLERS_BAR_HEIGHT } from './Home.constants';

const Home: FC<RouteChildrenProps> = () => {
  const { isLoading: isBitesLoading } = useSelector(bitesSelector);
  const { isLoading: isPlaylistLoading } = useSelector(playlistsSelector);
  const { handleBiteClick, handlePlaylistClick } = useHomeScreenLogic();
  const { logo, isLoadingLogo } = useGetOrganizationLogo();

  useRemoveQueryParams(['timestamp']);
  useEffect(() => {
    gtmTrack('pageview', {
      page_title: 'Home',
    });
  }, []);

  return (
    <S.Container>
      <ErrorModal />
      <NoContentModal />
      <OnlyTeamContentModal />
      <S.CompanyLogoContainer>
        {isLoadingLogo ? <Loader /> : <S.CompanyLogo src={logo} data-cy='logo' />}
      </S.CompanyLogoContainer>
      <ControllersBar />
      <S.Feed>
        <Route
          path='/bites'
          component={() => (isBitesLoading ? <S.FeedLoader /> : <InfiniteBiteList onBiteClick={handleBiteClick} />)}
        />
        <Route
          path='/playlists'
          component={() =>
            isPlaylistLoading ? <S.FeedLoader /> : <InfinitePlaylistList onPlaylistClick={handlePlaylistClick} />
          }
        />
      </S.Feed>
      <AskMeAnythingBottomSheet />
    </S.Container>
  );
};

const LOGO_CONTAINER_HEIGHT = 50;
const FEED_TOP_PADDING = 30;

const FEED_LOADER_TOP_OFFSET = HEADER_HEIGHT + LOGO_CONTAINER_HEIGHT + CONTROLLERS_BAR_HEIGHT + FEED_TOP_PADDING;
const FEED_LOADER_HEIGHT = 75;

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  `,
  CompanyLogoContainer: styled.div`
    height: ${LOGO_CONTAINER_HEIGHT}px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 37px;
  `,
  CompanyLogo: styled.img`
    max-width: 100%;
    height: ${LOGO_CONTAINER_HEIGHT}px;
  `,
  Feed: styled.div`
    padding-top: ${FEED_TOP_PADDING};
    width: 100%;
    height: 100%;
  `,
  FeedLoader: styled(FeedLoader)`
    margin: ${(window.innerHeight - FEED_LOADER_TOP_OFFSET) / 2 - FEED_LOADER_HEIGHT / 2}px auto 0;
    height: ${FEED_LOADER_HEIGHT}px;
    display: block;
  `,
};

export default Home;
