import { isProd } from './env';

export const IMAGE_MAX_SIZE = 10000000;
export const APP_WIDTH = 500;

export const APP_HEIGHT = 900;

export const NEW_FEED_ORG_IDS = isProd()
  ? [
      ...new Set([
        10479,
        15615,
        15614,
        15613,
        15623,
        15622,
        15621,
        15620,
        15619,
        15618,
        15617,
        15616,
        13779,
        10480,
        4314, // Notifications tests
        13579, // Ziv
      ]),
    ]
  : [...new Set([4314, 8068])];
