import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getNextPlaylistItem } from './utils';

import { getIsRtl } from '../../../locale/i18n';
import { selectedPlaylistSelector } from '../../../store/playlist/playlist.selectors';
import { selectedBiteSelector } from '../../../store/bite/bite.selectors';
import { ReactComponent as NextBite } from '../../../assets/icons/next-bite-btn.svg';
import { authSelector } from '../../../store/auth/auth.selectors';
import { proceedToNextBiteDataSet } from './goToNextBiteButton.constants';

interface IProps {
  isAbsolute?: boolean;
}

const GoToNextBiteButton: FC<IProps> = ({ isAbsolute }) => {
  const history = useHistory();
  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);
  const { selectedBite } = useSelector(selectedBiteSelector);
  const auth = useSelector(authSelector);
  const { t } = useTranslation();

  return (
    <S.Container
      isAbsolute={!isAbsolute}
      onClick={() => getNextPlaylistItem(history, selectedPlaylist, selectedBite, auth)}
    >
      <NextBite />
      <S.Text data-cy={proceedToNextBiteDataSet.cy}>{t('screens.ViewPlaylist.nextBite')}</S.Text>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ isAbsolute?: boolean }>`
    width: 72px;
    height: 100px;
    cursor: pointer;
    user-select: none;
    text-align: right;
    z-index: 5;
    ${({ isAbsolute }) => css`
      ${isAbsolute ? 'position: absolute' : ''};
      ${isAbsolute ? 'bottom: 100%' : ''};
    `}
    ${({ isAbsolute }) =>
      getIsRtl()
        ? `
                    ${isAbsolute ? 'left: -1px' : ''};
                    border-radius: 0 160px 0 0;
                    `
        : `
                    ${isAbsolute ? 'right: -1px' : ''};
                    border-radius: 160px 0 0 0;
                    `};

    svg {
      margin-top: 10px;
      ${() =>
        getIsRtl()
          ? `
                    margin-right: 10px;
                    transform: rotate(180deg)
                    `
          : 'margin-left: 10px;'};
    }
  `,
  Text: styled.div`
    color: ${({ theme }) => theme.colors.blue3};
    font-size: 12px;
    margin-right: 5px;
  `,
};

export default GoToNextBiteButton;
