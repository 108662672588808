import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
  memo,
  MutableRefObject,
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import { Loader } from '.';
import { ReactComponent as TranslationsSVG } from '../../assets/icons/translations.svg';
import ReactSelect from 'react-select';
import type { StylesConfig } from 'react-select';
import { getIsRtl } from '../../locale/i18n';
import { contentLocalesIsLoadingSelector, contentLocalesSelector } from '../../store/appActivity/appActivity.selectors';
import defaultTheme from '../../style/themes/defaultTheme';
import useTranslationOptions from '../../hooks/useTranslationOptions';

const translationDropdownComponents = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

const TranslationsDropdown: FC<{
  defaultLocale?: string;
  isLoading: boolean;
  containerStyles?: Record<string, string | number>;
  controlStyles?: Record<string, string | number>;
  dropdownStyles?: Record<string, string | number>;
  iconColor?: string;
  menuPortalTarget?: HTMLElement;
  withAnimation?: boolean;
  withDelayedDropdownOpen?: boolean;
  onOpen?: () => void;
  onSelect: (locale: string) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  disableInput?: boolean;
  containerRef?: MutableRefObject<HTMLDivElement>;
}> = ({
  defaultLocale = null,
  isLoading,
  containerStyles = {},
  controlStyles = {},
  dropdownStyles = {},
  iconColor = defaultTheme.colors.primaryBlue,
  menuPortalTarget,
  withAnimation,
  withDelayedDropdownOpen,
  onOpen,
  onSelect,
  menuPlacement = 'auto',
  disableInput,
  containerRef,
}) => {
  const locales = useSelector(contentLocalesSelector);
  const isLocalesLoading = useSelector(contentLocalesIsLoadingSelector);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const clickRef = useRef(false);

  const rtl = getIsRtl();

  const handleTranslationsClick = useCallback(() => {
    clickRef.current = true;
    setIsMenuVisible((prev) => !prev);
    onOpen?.();
  }, [onOpen]);

  const handleChange = useCallback(
    async (value) => {
      if (!locales) {
        return;
      }

      setIsMenuVisible(false);
      onSelect(value.locale);
    },
    [locales, onSelect],
  );

  const handleMenuClose = useCallback(() => {
    setIsMenuVisible(false);
  }, []);

  const { groupedOptions: options } = useTranslationOptions({ defaultLocale });

  const mergedContainerStyles: CSSProperties = useMemo(
    () => ({
      ...{
        position: 'relative',
      },
      ...containerStyles,
    }),
    [containerStyles],
  );

  const mergedControlStyles: CSSProperties = useMemo(
    () => ({
      ...{ backgroundColor: 'white', width: 32, height: 32, padding: 6 },
      ...controlStyles,
    }),
    [controlStyles],
  );

  const mergedDropdownStyles: CSSProperties = useMemo(
    () => ({
      ...{
        position: 'absolute',
        top: 2,
        right: 36,
      },
      ...dropdownStyles,
    }),
    [dropdownStyles],
  );

  useEffect(() => {
    setIsMenuOpen(isMenuVisible);
  }, [isMenuVisible]);

  const handleContainerClick = useCallback(() => {
    clickRef.current = true;
  }, []);

  useEffect(() => {
    const handleDocumentClick = () => {
      if (!clickRef.current) {
        handleMenuClose();
      }
      clickRef.current = false;
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [handleMenuClose]);

  const styles: StylesConfig = useMemo(() => {
    return {
      ...translationDropdownStyles,
      menu: (base) =>
        disableInput
          ? {
              ...base,
              marginTop: -40,
            }
          : base,
    };
  }, [disableInput]);

  if (!locales && !isLocalesLoading) {
    return null;
  }

  return (
    <S.Container style={mergedContainerStyles} ref={containerRef}>
      <S.Control style={mergedControlStyles} withAnimation={withAnimation} onClick={handleTranslationsClick}>
        {isLoading || isLocalesLoading ? (
          <Loader size='150%' />
        ) : (
          <TranslationsSVG width='100%' height='100%' fill={iconColor} />
        )}
      </S.Control>

      {isMenuVisible && (
        <div style={mergedDropdownStyles} onClick={handleContainerClick}>
          <ReactSelect
            isSearchable={!disableInput}
            isRtl={rtl}
            options={options}
            menuIsOpen={withDelayedDropdownOpen ? isMenuOpen : true}
            onChange={handleChange}
            styles={styles}
            onMenuClose={handleMenuClose}
            menuPlacement={menuPlacement}
            minMenuHeight={300}
            components={translationDropdownComponents}
            menuPortalTarget={menuPortalTarget}
          />
        </div>
      )}
    </S.Container>
  );
};
export default memo(TranslationsDropdown);

const scaleDownAnimation = keyframes`
  0% {
    transform: scale(2);
    opacity: 0;
  }
  30% {
    transform: scale(2);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

const S = {
  Container: styled.div`
    z-index: 100;
    -webkit-transform: translate3d(0, 0, 0);
  `,
  Control: styled.div<{ withAnimation: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};

    ${({ withAnimation }) =>
      withAnimation
        ? css`
            animation: ${scaleDownAnimation} 3s ease;
          `
        : ''}
  `,
};

const translationDropdownStyles = {
  control: (base) => ({
    ...base,
    width: '150px',
  }),
};
