import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { VideoPlayer } from '../..';
import { ReactComponent as CloseSVG } from '../../../../assets/icons/close2.svg';
import { enableVideoPlaying } from '../../../../store/bite/bite.actions';
import { GtmVideo } from '../../../../services/googleTagManager';
import useVimeoLink from '../../../../hooks/useVimeoLinks';
import SubtitlesTranslations from './SubtitlesTranslations';
import { videoModalDataSet } from './videoModal.constants';
import requestVideoFullscreen from '../../../../utils/video/requestVideoFullscreen';
import { useTranslation } from 'react-i18next';
import { getIsRtl } from '../../../../locale/i18n';
import getIsMobileDevice from '../../../../utils/getIsMobileDevice';
import useScreenOrientation from '../../../../hooks/useScreenOrientation';
import { ISubtitles, ITranslatedVoiceover } from '../../../../types/bite';
import { translatedVoiceoversAudios } from '../../../../store/bite/bites.data';
import { logError } from '../../../../store/tracking/tracking.slice';
import defaultTheme from '../../../../style/themes/defaultTheme';

interface IProps {
  closeModal: () => void;
  media_url: string | null;
  taskId?: string;
  file_type: 'video' | 'youtube' | 'image' | 'google_slides';
  videoStartsAt: null | number;
  videoEndsAt: null | number;
  is_cc_enabled?: boolean;
  isSkipable?: boolean;
  isVideoWatchCompleted?: boolean;
  isShowTopBanner?: boolean;
  isTranslatingSubtitles?: boolean;
  isShowVoiceoverTranslationsOverlay?: boolean;
  subtitlesDisabled?: boolean;
  onFullyWatched: () => void;
  section?: string | null;
  currentSubtitles?: { url: string; locale: string };
  subtitles?: ISubtitles[];
  onSelectSubtitlesLang?: (props: { locale: string; isManually?: boolean }) => void;
  audio?: ITranslatedVoiceover;
  playerRef?: React.RefObject<ReactPlayer>;
}

const TOP_BANNER_HEIGHT = 33;
const VIDEO_NATIVE_CONTROLS_HEIGHT = 100;
const FULLSCREEN_BTN_HEIGHT = 50;
const FULLSCREEN_BTN_MARGIN = 8;

const VideoModal: FC<IProps> = ({
  media_url,
  taskId,
  file_type,
  videoStartsAt,
  videoEndsAt,
  is_cc_enabled,
  isSkipable,
  isVideoWatchCompleted,
  isShowTopBanner,
  isTranslatingSubtitles,
  isShowVoiceoverTranslationsOverlay,
  subtitlesDisabled,
  onFullyWatched,
  closeModal,
  section,
  currentSubtitles,
  subtitles,
  onSelectSubtitlesLang,
  audio,
  playerRef,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isMobileDevice = getIsMobileDevice();
  const { isScreenLandscape } = useScreenOrientation();
  const [fullscreenButtonOffsetTop, setFullscreenButtonOffsetTop] = useState<number>(null);

  const [isVideoLandscape, setIsVideoLandscape] = useState(false);
  const [isShowLangsOverlay, setIsShowLangsOverlay] = useState(false);

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const internalPlayerRef = useRef<ReactPlayer>(null);
  playerRef = playerRef || internalPlayerRef;

  const { vimeoVideoUri } = useVimeoLink(file_type === 'video', media_url);

  const onClickCloseIcon = () => {
    onExitVideo();
    dispatch(enableVideoPlaying());
    closeModal();
  };

  const requestPlayerFullscreen = () => {
    const videoPlayer = playerRef.current.getInternalPlayer() as HTMLVideoElement;
    requestVideoFullscreen(videoPlayer);
  };

  const onExitVideo = () => {
    const tagManagerVideo = new GtmVideo(
      'Video Existed',
      playerRef.current?.getCurrentTime(),
      playerRef.current?.getDuration(),
      vimeoVideoUri,
      vimeoVideoUri ? 'Vimeo' : 'Other',
    );

    tagManagerVideo.sendData();
  };

  const audioAppliedRef = useRef(false);

  const resetTranslatedAudiosRef = useRef(() => {
    audioAppliedRef.current = false;
  });
  const applyAudio = useCallback(async () => {
    if (audioAppliedRef.current) {
      return;
    }
    audioAppliedRef.current = true;

    const audioInstance: HTMLAudioElement = translatedVoiceoversAudios.current[taskId][audio.locale];

    const player = playerRef.current.getInternalPlayer();
    player.pause();
    player.currentTime = 0;

    const updatePositionAndPlay = () => {
      audioInstance?.pause();

      if (player.currentTime > audioInstance.duration) {
        return;
      }

      const pc = player.currentTime / audioInstance.duration;

      audioInstance.currentTime = audioInstance.duration * pc;
      audioInstance.volume = 1;
      player.volume = 0;

      audioInstance.play().catch((error: any) => {
        dispatch(
          logError({
            event: 'VideoModal: translated audio play error',
            data: {
              errorMessage: error?.message,
              errorStack: error?.stack,
            },
          }),
        );
      });

      updateResetTranslatedAudiosRef();
    };

    const updateResetTranslatedAudiosRef = () => {
      resetTranslatedAudiosRef.current = () => {
        audioAppliedRef.current = false;

        // reset audio
        audioInstance?.pause();

        // clear player events
        player.removeEventListener('play', updatePositionAndPlay);
        player.removeEventListener('seeked', updatePositionAndPlay);
        player.removeEventListener('pause', handlePlayerPause);
      };
    };

    const handlePlayerPause = () => {
      audioInstance?.pause();
    };

    player.addEventListener('play', updatePositionAndPlay);
    player.addEventListener('seeked', updatePositionAndPlay);
    player.addEventListener('pause', handlePlayerPause);

    player.play();
    updatePositionAndPlay();
  }, [audio?.locale, dispatch, playerRef, taskId]);

  const handleVideoMetaDataLoaded = useCallback(() => {
    if (!vimeoVideoUri) {
      return;
    }

    if (audio?.audioStorage && !audio.videoStorageStreaming && taskId) {
      applyAudio();
    }

    const videoPlayer = playerRef.current.getInternalPlayer() as HTMLVideoElement;
    const isLandscape = videoPlayer.videoWidth > videoPlayer.videoHeight;
    setIsVideoLandscape(isLandscape);

    if (!isLandscape) {
      return;
    }

    const topBannerHeight = isShowTopBanner ? TOP_BANNER_HEIGHT : 0;
    const videoRenderedPc = videoContainerRef.current.getBoundingClientRect().width / videoPlayer.videoWidth;
    const videoRenderedHeight = videoPlayer.videoHeight * videoRenderedPc;
    const spaceOnTopBottom = (document.documentElement.clientHeight - topBannerHeight - videoRenderedHeight) / 2;

    if (spaceOnTopBottom - FULLSCREEN_BTN_MARGIN * 2 - VIDEO_NATIVE_CONTROLS_HEIGHT >= FULLSCREEN_BTN_HEIGHT) {
      const offsetTop = topBannerHeight + spaceOnTopBottom + videoRenderedHeight + FULLSCREEN_BTN_MARGIN;
      setFullscreenButtonOffsetTop(offsetTop);
    }
  }, [applyAudio, audio, isShowTopBanner, playerRef, taskId, vimeoVideoUri]);

  useEffect(() => {
    resetTranslatedAudiosRef.current?.();
    const player = playerRef.current?.getInternalPlayer();

    if (vimeoVideoUri && player && taskId) {
      if (audio?.audioStorage && !audio.videoStorageStreaming) {
        applyAudio();
      } else {
        player.volume = 1;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  useEffect(() => {
    if (subtitlesDisabled) {
      // reset audio playback when disabling subtitles
      resetTranslatedAudiosRef.current?.();
    }
  }, [subtitlesDisabled]);

  useEffect(() => {
    return () => {
      // reset audio playback when unmounting component
      resetTranslatedAudiosRef.current?.();
    };
  }, []);

  return (
    <S.Container
      isVideoLandscape={isMobileDevice && isVideoLandscape && isScreenLandscape === false}
      data-cy={videoModalDataSet.cy}
    >
      <S.CloseContainer onClick={onClickCloseIcon}>
        <CloseSVG width='18px' height='18px' fill={defaultTheme.colors.white} />
      </S.CloseContainer>

      {isShowTopBanner && (
        <S.TopBanner>
          {t('screens.viewBite.watchTillEndBanner.videoFullscreen.text1')}{' '}
          <S.WatchTillEndBannerBoldText>
            {t('screens.viewBite.watchTillEndBanner.videoFullscreen.text2')}
          </S.WatchTillEndBannerBoldText>
        </S.TopBanner>
      )}

      <S.VideoContainer ref={videoContainerRef}>
        <VideoPlayer
          onFullyWatched={onFullyWatched}
          onVideoMetaDataLoaded={handleVideoMetaDataLoaded}
          audio={audio}
          {...{
            media_url,
            file_type,
            videoStartsAt,
            videoEndsAt,
            is_cc_enabled,
            isSkipable,
            isVideoWatchCompleted,
            closeModal,
            playerRef,
            section,
            muted: !!audio?.audioStorage && !audio.videoStorageStreaming,
            loop: false,
            playing: !isShowLangsOverlay && !isShowVoiceoverTranslationsOverlay,
            controls: true,
            isFullScreen: true,
            subtitles_url: currentSubtitles.url,
            subtitles_locale: currentSubtitles.locale,
          }}
        />
        {onSelectSubtitlesLang && (currentSubtitles.url || subtitlesDisabled) && (
          <SubtitlesTranslations
            subtitles={subtitles}
            currentLocale={currentSubtitles.locale}
            isLoading={isTranslatingSubtitles}
            isShowLangsOverlay={isShowLangsOverlay}
            subtitlesDisabled={subtitlesDisabled}
            setIsShowLangsOverlay={setIsShowLangsOverlay}
            onSelect={onSelectSubtitlesLang}
          />
        )}
      </S.VideoContainer>

      {fullscreenButtonOffsetTop !== null && !isVideoLandscape && (
        <S.FullscreenBtn onClick={requestPlayerFullscreen} top={fullscreenButtonOffsetTop}>
          {t('screens.viewBite.Video.openVideoFullscreen')}
        </S.FullscreenBtn>
      )}
    </S.Container>
  );
};
export const S = {
  Container: styled.div<{ isVideoLandscape: boolean }>`
    z-index: 20;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.darkBlueBg};

    ${({ isVideoLandscape }) =>
      isVideoLandscape &&
      css`
        position: fixed;
        transform: rotate(90deg);
        transform-origin: top left;
        left: 100dvw;
        width: 100dvh;
        height: 100dvw;
      `}
  `,

  TopBanner: styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: ${TOP_BANNER_HEIGHT}px;
    color: white;
    font-size: 18px;
    text-align: center;
    ${() =>
      getIsRtl() &&
      `
			flex-direction: row-reverse;
		`};
    background: ${({ theme }) =>
      `linear-gradient(149.47deg, ${theme.colors.lightPurple1} 21.66%, ${theme.colors.orange1} 73.79%)`};
  `,
  WatchTillEndBannerBoldText: styled.span`
    font-weight: 500;
  `,

  VideoContainer: styled.div`
    z-index: 21;
    flex: 1;
    height: 100%;

    /* this magically makes the video fit into the screen */
    overflow: hidden;

    video {
      object-fit: contain;
      flex: 1;
    }
  `,

  CloseContainer: styled.div`
    z-index: 999;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 8px;
  `,

  Instructions: styled.p`
    text-align: center;
    margin-top: 68px;
    font-size: 16px;
    line-height: 20px;
    ${({ theme }) => css`
      color: ${theme.colors.white};
    `}
  `,
  FullscreenBtn: styled.button<{ top: number }>`
    position: absolute;
    top: ${({ top }) => top}px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
    width: auto;
    min-width: 50%;
    height: ${FULLSCREEN_BTN_HEIGHT}px;
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray19};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.transparentBlack};
    cursor: pointer;
  `,
};

export default VideoModal;
