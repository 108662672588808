import React, { useRef, useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import Flickity from 'react-flickity-component';

import Card from './Card';
import IndicatorList from './IndicatorList';

import { useTranslation } from 'react-i18next';
import GoToNextBiteBlueButton from '../../GoToNextBite/GoToNextBiteBlueButton';
import { ICard } from '../../../../types/summary';
import useMoveToNextBiteSection from '../../../../hooks/useMoveToNextBiteSection';
import { ContinueButton } from '../../../../components/shared';
import { getIsRtl } from '../../../../locale/i18n';
import gtmTrack from '../../../../services/googleTagManager/track';
import { summaryContinueButtonDataSet } from './carousel.constants';

interface IProps {
  cards: ICard[];
  summaryTranslations: string[];
  hasDiscussion: boolean;
}

const Carusel: FC<IProps> = ({ cards, summaryTranslations, hasDiscussion }) => {
  const { proceedToNextSection, nextSectionPath } = useMoveToNextBiteSection({
    sectionType: 'summary',
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const flickityRef = useRef(null);

  useEffect(() => {
    const handler = (index: number) => {
      onChangeCurrentIndex(index);
    };

    flickityRef.current.on('change', handler);

    return () => {
      flickityRef.current.off('change.flickity', handler);
    };
  }, []);

  const onChangeCurrentIndex = (index: number) => {
    setCurrentIndex(index);
    flickityRef.current.select(index);
  };

  const needShowNextArrow = window.location.search.includes('playlistId') && !hasDiscussion;

  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Top>
        <S.FlickityContainer>
          <Flickity
            options={{
              rightToLeft: getIsRtl(),
              prevNextButtons: false,
            }}
            flickityRef={(c) => (flickityRef.current = c)}
          >
            {cards.map((card, idx) => (
              <Card
                card={card}
                summaryTranslation={summaryTranslations?.[idx]}
                position={{ cardPosition: idx + 1, totalCards: cards.length }}
                key={idx}
              />
            ))}
          </Flickity>
        </S.FlickityContainer>

        <IndicatorList currentIndex={currentIndex} totalLength={cards.length} onChangeIndex={onChangeCurrentIndex} />
      </S.Top>

      {cards.length > 1 && <S.ScrollingTip>{t('screens.Summary.scrollingTip')}</S.ScrollingTip>}

      {nextSectionPath ? (
        <ContinueButton
          data-cy={summaryContinueButtonDataSet.cy}
          text={t('screens.Summary.promptForComments')}
          onClick={() => {
            proceedToNextSection();
            gtmTrack('summary_next_button', {});
          }}
        />
      ) : (
        needShowNextArrow && <GoToNextBiteBlueButton />
      )}
    </S.Container>
  );
};

const S: any = {};

S.ScrollingTip = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray19};
`;

S.Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 30px 30px 30px;
  overflow-x: visible;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .flickity-enabled:focus {
    outline: none;
  }

  ol {
    display: none;
  }
`;

S.Top = styled.div``;

S.FlickityContainer = styled.div`
  margin-bottom: 45px;
`;

export default React.memo(Carusel);
