import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { addCommentRequest } from '../../../../store/bite/bite.actions';
import {
  commentSuggestionSelector,
  selectAddCommentSelector,
  selectedBiteSelector,
} from '../../../../store/bite/bite.selectors';
import { ReactComponent as CloseSVG } from '../../../../assets/icons/close.svg';
import { IComment } from '../../../../types/bite';
import GoToNextBiteButton from '../../GoToNextBite/GoToNextBiteButton';
import SendButton from '../../../../components/shared/Buttons/SendButton';
import { getIsRtl } from '../../../../locale/i18n';
import { ReactComponent as CameraIcon } from '../../../../assets/icons/camera-3.svg';
import gtmTrack from '../../../../services/googleTagManager/track';
import { authSelector } from '../../../../store/auth/auth.selectors';
import useQueryParams from '../../../../hooks/useQueryParams';
import { commentInputDataSet } from './createComment.constants';
import CommentSuggestion from './CommentSuggestion';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import getRandomArrayValues from '../../../../utils/getRandomArrayValues';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface IProps {
  inputRef: any;
  comment: IComment;
  isGuest: boolean;
  setSelectedComment: (foo: null) => void;
  isLastBiteInPlaylist: boolean;
}

const TOOLTIP_ID = 'commentInputWrapper';
const COMMENTS_MAX_LENGTH = 5000;

const CreateComment: FC<IProps> = ({ inputRef, comment, setSelectedComment, isGuest, isLastBiteInPlaylist }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectAddCommentSelector);
  const { selectedBite: selectedBiteShare } = useSelector(selectedBiteSelector);
  const { comment: commentSuggestion, isLoading: isCommentSuggestionLoading } = useSelector(commentSuggestionSelector);
  const user = useSelector(authSelector);
  const { prefixedT } = useCustomTranslation('components.predefinedComments');

  const [commentText, setCommentText] = useState('');
  const [hasCommented, setHasCommented] = useState(false);
  const [commentError, setCommentError] = useState<string | null>();

  const errorTimeoutRef = useRef(null);

  const [image, setImage] = useState(null);

  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;

  const isCreator = user?.id === selectedBiteShare.creator_id;

  const handleRemoveImage = () => {
    setImage(null);
  };

  const handleFormSubmit = (ev) => {
    ev.preventDefault();

    handleError(null);

    if (!(commentText.length || image)) {
      return;
    }
    const commentID = comment ? comment.id : selectedBiteShare.id;
    const commentType = comment ? 'comment' : 'biteshare';
    setHasCommented(true);
    setSelectedComment(null);

    gtmTrack('post_comment', {
      bites_user_id: user?.id,
      bite_id: selectedBiteShare.id,
      bite_share_id: selectedBiteShare.id,
      content_org_id: selectedBiteShare.orgid,
      sharing_mode: selectedBiteShare.sharing_mode,
      playlist_id: playlistId,
    });
    dispatch(addCommentRequest({ text: commentText, file: image, type: commentType }, commentID));

    setCommentText('');
    handleRemoveImage();
  };

  const handleError = useCallback((error) => {
    clearTimeout(errorTimeoutRef.current);

    setCommentError(error);

    if (error) {
      errorTimeoutRef.current = setTimeout(() => {
        setCommentError(null);
      }, 5000);
    }
  }, []);

  const handleInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (ev.target.value.length > COMMENTS_MAX_LENGTH) {
        handleError(t('comments.maxCommentLength'));
        const cuttedText = ev.target.value.slice(0, COMMENTS_MAX_LENGTH);

        if (cuttedText === commentText) {
          return;
        }

        setCommentText(cuttedText);
        return;
      }

      handleError(null);
      setCommentText(ev.target.value);
    },
    [commentText, handleError, t],
  );

  const handleChangeImage = (e) => {
    const { files } = e.target;
    if (files.length === 0) {
      // user clicked image picker and canceled
      return;
    }
    setImage(files[0]);
    // if (files[0].size < IMAGE_MAX_SIZE) {
    //   setImage(files[0])
    // }
  };

  const isSendDisabled = () => {
    return isLoading || (commentText.length === 0 && !image);
  };

  const needShowNextArrow = window.location.search.includes('playlistId') && !isLastBiteInPlaylist;

  const predefinedComments = useMemo(() => {
    if (hasCommented) {
      return null;
    }
    const suggestedComments = [
      prefixedT('thankYou'),
      prefixedT('Interesting'),
      prefixedT('greatBite'),
      prefixedT('veryHelpful'),
      prefixedT('gotIt'),
      prefixedT('wow'),
      prefixedT('Cool'),
    ];
    const preDefinedEmojis = ['👍🏽', '🤩', '❤️', '😊', '💪🏽', '⭐'];

    const pickedComments = getRandomArrayValues(suggestedComments, 3);
    const pickedEmojis = getRandomArrayValues(preDefinedEmojis, 2);

    if (commentSuggestion) {
      return [commentSuggestion, prefixedT('adminComment')];
    }
    if (isCreator) {
      return [prefixedT('adminComment')];
    }
    return [...pickedEmojis.slice(0, 2), ...pickedComments.slice(0, 3)];
  }, [commentSuggestion, hasCommented, isCreator, prefixedT]);

  const handleCommentSuggestionSelected = useCallback((selectedComment: string) => {
    setCommentText(selectedComment);
  }, []);

  const handleInputBlur = useCallback(() => {
    handleError(null);
  }, [handleError]);

  return (
    <S.Container>
      {needShowNextArrow && <GoToNextBiteButton />}
      {!hasCommented && (
        <CommentSuggestion
          comments={predefinedComments}
          onCommentSelected={handleCommentSuggestionSelected}
          isLoading={isCommentSuggestionLoading}
          isCreator={isCreator}
        />
      )}
      <S.Form onSubmit={handleFormSubmit}>
        {!isGuest && (
          <S.ImageUploadLabel className='icon' id='btnUploadImage'>
            <S.CameraIcon />
            <S.ImageUploadInput
              onChange={handleChangeImage}
              type='file'
              accept='image/jpeg, image/png, image/jpg'
              id='btnUploadImageIcon'
            />
          </S.ImageUploadLabel>
        )}
        <S.InputWrapper id={TOOLTIP_ID}>
          <S.Input
            value={commentText}
            onChange={handleInputChange}
            disabled={isLoading}
            onBlur={handleInputBlur}
            ref={inputRef}
            autoComplete='off'
            id='commentInput'
            data-cy={commentInputDataSet.cy}
          />
          <S.Tooltip anchorId={TOOLTIP_ID} isOpen={!!commentError} content={commentError} />
        </S.InputWrapper>
        <SendButton type='submit' isLoading={isLoading} disabled={isSendDisabled()} id='sendCommentButtonIcon' />
      </S.Form>
      {image && (
        <S.ImagePreviewOuterContainer>
          <S.ImagePreviewContainer>
            <S.ImageUploadPreview src={URL.createObjectURL(image)} alt='' />
            <S.ImagePreviewRemove onClick={handleRemoveImage} id='btnRemoveImage'>
              <CloseSVG style={{ height: 5, width: 5 }} />
            </S.ImagePreviewRemove>
          </S.ImagePreviewContainer>
        </S.ImagePreviewOuterContainer>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    min-height: 44px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    white-space: initial;
    background: #fff;
    position: relative;
  `,

  GoToNextPlaylistBite: styled.div`
    position: absolute;
    bottom: 100%;
    width: 61px;
    height: 60px;
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 5;
    ${() =>
      getIsRtl()
        ? `
				left: -1px;
				border-radius: 0 160px 0 0;
				`
        : `
				border-radius: 160px 0 0 0;
				right: -1px;
				`};

    svg {
      margin-top: 10px;
      ${() =>
        getIsRtl()
          ? `
				margin-right: 10px;
				transform: rotate(180deg)
				`
          : 'margin-left: 10px;'};
    }
  `,

  Form: styled.form`
    height: 44px;
    padding: 4.5px 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray17};
    ${() => getIsRtl() && 'direction: rtl'};
    writing-mode: horizontal-tb;
  `,

  ImageUploadLabel: styled.label`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    cursor: pointer;
  `,
  CameraIcon: styled(CameraIcon)`
    width: 20px;
    cursor: pointer;
    height: auto;
    fill: white;
  `,

  ImageUploadInput: styled.input`
    display: none;
  `,

  InputWrapper: styled.div`
    border-radius: 100px;
    flex: 1;
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;
    ${() =>
      getIsRtl()
        ? `
        padding-right: 13px;
        padding-left: 11px;
        `
        : `
        padding-left: 13px;
        padding-right: 11px;

`}

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ theme }) => css`
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.lightGray11};
    `}
  `,

  Input: styled.input`
    font-size: 16px;
    min-height: 30px;
    width: 100%;
    padding: 0 5px;
    ${({ theme, disabled }) => css`
      color: ${theme.colors.darkBlueBg};
      ${disabled && `color: ${theme.colors.lightGray2};`}
    `}
  `,

  ImagePreviewOuterContainer: styled.div`
    display: flex;
    padding: 10px;
  `,
  ImagePreviewContainer: styled.div`
    position: relative;
    margin: 0 auto;
  `,

  ImageUploadPreview: styled.img`
    max-width: 100%;
    height: 60px;
    border-radius: 3px;
    vertical-align: top;
  `,

  ImagePreviewRemove: styled.div`
    position: absolute;
    left: calc(100% - 5px);
    top: -5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #bfbebe;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4a4949;
    cursor: pointer;
  `,

  Tooltip: styled(Tooltip)`
    ${({ theme }) => css`
      background: ${theme.colors.pinkError};
    `}
  `,
};

export default CreateComment;
